export const emailLink = 'mailto: sumeetrohra1@gmail.com';
export const instagramLink = 'https://www.instagram.com/mazak_mazak_me_chai_girr_gayi/';
export const mediumLink = 'https://medium.com/@rohrasumeet';
export const linkedinLink = 'https://www.linkedin.com/in/sumeetrohra/';
export const githubLink = 'https://github.com/sumeetrohra';
export const youtubeUrl =
  'https://www.youtube.com/channel/UCSvh1Fq1FUzaDWKQZ-9kViw';
export const npmUrl = 'https://www.npmjs.com/~rohrasumeet';

export const profileImage =
  'https://drive.google.com/uc?id=1bPxV-XQ3bR9l6x0yfWq4SIDOi31KLOyp';
